/**
 * slider
 * 「.slider」
 */

import Swiper from "swiper"
import { Navigation, Pagination, Scrollbar, Thumbs } from "swiper/modules"
import { functions } from "../../../../modules/functions"

export namespace SliderModule {
	export class Service {
		constructor() {
			document.addEventListener("readCompleteAction", () => {
				Service.setEvent()
			})
		}

		public static setEvent() {
			const $sliders = document.querySelectorAll<HTMLElement>(".slider")
			$sliders.forEach(($slider) => {
				const $swiper = $slider.querySelector(".swiper:not(.swiper-thumbnail)")
				const $thumbnail = $slider.querySelector(".swiper-thumbnail")

				if ($swiper) {
					const $swiperId = functions.createUniqueID()
					$swiper.setAttribute("id", $swiperId)

					if ($thumbnail) {
						const $thumbnailId = functions.createUniqueID()
						$thumbnail.setAttribute("id", $thumbnailId)
						const $thumbnailSwiper = new Swiper("#" + $thumbnailId, {
							modules: [Navigation, Pagination, Scrollbar, Thumbs],
							slidesPerView: "auto",
							freeMode: true,
							watchSlidesProgress: true,
						})

						new Swiper("#" + $swiperId, {
							modules: [Navigation, Pagination, Scrollbar, Thumbs],
							loop: false,
							pagination: {
								el: ".swiper-pagination",
								type: "bullets",
								clickable: true,
							},
							navigation: {
								nextEl: ".swiper-button-next",
								prevEl: ".swiper-button-prev",
							},
							thumbs: {
								swiper: $thumbnailSwiper,
							},
						})
					} else {
						new Swiper("#" + $swiperId, {
							modules: [Navigation, Pagination, Scrollbar, Thumbs],
							loop: true,
							pagination: {
								el: ".swiper-pagination",
								type: "bullets",
								clickable: true,
							},
							navigation: {
								nextEl: ".swiper-button-next",
								prevEl: ".swiper-button-prev",
							},
						})
					}
				}
			})
		}
	}
}
