export namespace Ease {
	export const EaseFunction = {
		linear: (t: number) => t,
		easeInOut: (t: number) => (t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1),
		easeInQuad: (t: number) => t * t,
		easeOutQuad: (t: number) => t * (2 - t),
		easeInOutQuad: (t: number) => (t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t),
		easeInCubic: (t: number) => t * t * t,
		easeOutCubic: (t: number) => --t * t * t + 1,
		easeInOutCubic: (t: number) => (t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1),
		easeInQuart: (t: number) => t * t * t * t,
		easeOutQuart: (t: number) => 1 - --t * t * t * t,
		easeInOutQuart: (t: number) => (t < 0.5 ? 8 * t * t * t * t : 1 - 8 * --t * t * t * t),
		easeInQuint: (t: number) => t * t * t * t * t,
		easeOutQuint: (t: number) => 1 + --t * t * t * t * t,
		easeInOutQuint: (t: number) => 1 + --t * t * t * t * t,
		easeInElastic: (t: number) => (0.04 - 0.04 / t) * Math.sin(25 * t) + 1,
		easeOutElastic: (t: number) => ((0.04 * t) / --t) * Math.sin(25 * t),
		easeInOutElastic: (t: number) =>
			(t -= 0.5) < 0 ? (0.01 + 0.01 / t) * Math.sin(50 * t) : (0.02 - 0.01 / t) * Math.sin(50 * t) + 1,
	}
}
