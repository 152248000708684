/**
 * マウスドラッグスクロール
 */

export namespace MouseDragScrollModule {
	export class Service {
		constructor() {
			document.addEventListener("readCompleteAction", () => {
				Service.mouseDragScrollSetEvent()
			})
		}

		public static mouseDragScrollSetEvent() {
			const $targetElements = document.querySelectorAll<HTMLElement>(".js__mouseDragScroll")
			$targetElements.forEach(($targetElement) => {
				$targetElement.addEventListener("mousedown", ($event) => {
					const $eventTargetElement = $event.target as HTMLElement
					const $range = document.createRange()
					let $flg = false
					for (let $i = 0; $i < $eventTargetElement.childNodes.length; $i++) {
						const $n = $eventTargetElement.childNodes[$i]
						if ($n.nodeType == Node.TEXT_NODE) {
							// TextNodeのすべての文字を表す範囲を指定する
							const $txt = $n.textContent
							$range.setStart($n, 0)
							$range.setEnd($n, $txt?.length ?? 0)
							// TextNode内の各行の絶対座標を取得する。
							const $rects = $range.getClientRects()
							for (let $j = 0; $j < $rects.length; $j++) {
								const $rect = $rects[$j]
								// 各行について座標と範囲を判定する
								if (
									$rect.left <= $event.clientX &&
									$event.clientX <= $rect.right &&
									$rect.top <= $event.clientY &&
									$event.clientY <= $rect.bottom
								) {
									$flg = true
									break
								}
							}
						}
					}

					if ($flg) {
						return
					}

					$event.preventDefault()
					$targetElement.setAttribute("data-down", "true")
					$targetElement.setAttribute("data-move", "false")
					$targetElement.setAttribute("data-x", $event.clientX.toString())
					$targetElement.setAttribute("data-y", $event.clientY.toString())
					$targetElement.setAttribute("data-scrollleft", $targetElement.scrollLeft.toString())
					$targetElement.setAttribute("data-scrolltop", $targetElement.scrollTop.toString())
					return false
				})

				$targetElement.addEventListener("click", ($event) => {
					if ("true" === $targetElement.getAttribute("data-move")) {
						return false
					}
				})
			})

			document.addEventListener("mousemove", ($event) => {
				const $targetElement = document.querySelector('.js__mouseDragScroll[data-down="true"]')
				if ($targetElement) {
					$event.preventDefault()
					const $x = $targetElement.getAttribute("data-x")
					const $y = $targetElement.getAttribute("data-y")
					const $scrollleft = $targetElement.getAttribute("data-scrollleft")
					const $scrolltop = $targetElement.getAttribute("data-scrolltop")
					let $move_x: number
					let $move_y: number
					if ($x && $y && $scrollleft && $scrolltop) {
						$move_x = Number.parseFloat($x) - $event.clientX
						$move_y = Number.parseFloat($y) - $event.clientY
						if ($move_x !== 0 || $move_y !== 0) {
							$targetElement.setAttribute("data-move", "true")
						} else {
							return
						}
						$targetElement.scrollLeft = Number.parseFloat($scrollleft) + $move_x
						$targetElement.scrollTop = Number.parseFloat($scrolltop) + $move_y
						return false
					}
				}
			})

			document.addEventListener("mouseup", ($event) => {
				const $targetElement = document.querySelector('.js__mouseDragScroll[data-down="true"]')
				if ($targetElement) {
					$targetElement.setAttribute("data-down", "false")
				}
			})
		}
	}
}
