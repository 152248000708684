export namespace Offset {
	export function getTop($element: HTMLElement) {
		const $rect = $element.getBoundingClientRect()
		const $scrollTop = window.pageYOffset || document.documentElement.scrollTop
		return $rect.top + $scrollTop
	}
	export function geRright($element: HTMLElement) {
		const $left = Offset.getLeft($element)
		return $left + $element.offsetWidth
	}

	export function geBottom($element: HTMLElement) {
		const $top = Offset.getTop($element)
		return $top + $element.offsetHeight
	}

	export function getLeft($element: HTMLElement) {
		const $rect = $element.getBoundingClientRect()
		const $scrollTop = window.pageXOffset || document.documentElement.scrollLeft
		return $rect.left + $scrollTop
	}
}
