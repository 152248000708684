/**
 * ツールチップ
 * 「.js__tooltip」に対して処理をする
 * id必須です。
 * aタグで<a href="#js__tooltip">ツールチップ</a>
 * 「data-action="open"」となっている時は画面表示した際に開く
 * 
<div id="js__tooltip" class="js__tooltip" data-action="open">
  ...
</div>
 */

export namespace TooltipModule {
	export class Service {
		constructor() {
			document.addEventListener("readCompleteAction", () => {
				Service.TooltipSetEvent()
			})
		}

		public static TooltipSetEvent() {
			const $elements = document.querySelectorAll(".js__tooltip")
			if ($elements) {
				$elements.forEach(($element) => {
					const $elementId = $element.getAttribute("id")
					const $elementAsHTMLElement = $element as HTMLElement
					const $action = $element.getAttribute("data-action")

					$elementAsHTMLElement.style.opacity = "0"
					$elementAsHTMLElement.style.visibility = "hidden"
					$elementAsHTMLElement.style.position = "relative"
					$elementAsHTMLElement.innerHTML =
						'<div class="js__tooltip__body" style="position:absolute;">' + $elementAsHTMLElement.innerHTML + "</div>"

					if ($elementId) {
						const OtherClickEvent = ($event: Event) => {
							const $targetElements = document.querySelectorAll(".js__tooltip.js__tooltip--isActive")
							const $eventTarget = $event.target as Element

							if (
								$eventTarget.classList.contains("js__tooltip") &&
								!$eventTarget.classList.contains("js__tooltip--isActive")
							) {
								return
							}

							if ($eventTarget.closest(".js__tooltip")) {
								return
							}

							if (
								($eventTarget.nodeName === "a" && $eventTarget.getAttribute("href") === "#" + $elementId) ||
								($eventTarget.closest("a") && $eventTarget.closest("a")?.getAttribute("href") === "#" + $elementId)
							) {
								return
							}

							if ($targetElements) {
								$targetElements.forEach(($targetElement) => {
									$elementAsHTMLElement.classList.remove("js__tooltip--isActive")
									$elementAsHTMLElement.style.opacity = "0"
									$elementAsHTMLElement.style.visibility = "hidden"
								})
							}
						}

						if ("open" === $action) {
							$elementAsHTMLElement.classList.add("js__tooltip--isActive")
							$elementAsHTMLElement.style.opacity = "1"
							$elementAsHTMLElement.style.visibility = "visible"
							// ツールチップ以外をクリック
							document.addEventListener("click", OtherClickEvent, true)
						}

						const $targetElements = document.querySelectorAll('[href="#' + $elementId + '"]')
						if ($targetElements) {
							$targetElements.forEach(($targetElement) => {
								$targetElement.addEventListener("click", ($event) => {
									$event.preventDefault()
									const $self = $targetElement
									document.body.classList.toggle("js__tooltip--isActive")
									if (!$elementAsHTMLElement.classList.contains("js__tooltip--isActive")) {
										$elementAsHTMLElement.classList.add("js__tooltip--isActive")
										$elementAsHTMLElement.style.opacity = "1"
										$elementAsHTMLElement.style.visibility = "visible"
										// ツールチップ以外をクリック
										document.addEventListener("click", OtherClickEvent, true)
									} else {
										$elementAsHTMLElement.classList.remove("js__tooltip--isActive")
										$elementAsHTMLElement.style.opacity = "0"
										$elementAsHTMLElement.style.visibility = "hidden"
										// ツールチップ以外をクリック
										document.removeEventListener("click", OtherClickEvent, true)
									}
								})
							})
						}
					}
				})
			}
		}
	}
}
