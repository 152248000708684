/**
 * トグルインプットタイプ
 * 「.js__toggleInputType」に対して処理をする
 * 「data-target」必須 ターゲットのIDなどを指定
 * 「data-type」必須 変更するタイプを指定
 * 
<a class="js__toggleInputType" data-target="#js__toggleInputType" data-type="text">
  <span></span>
</a>

<input id="js__toggleInputType" type="password">
 */

export namespace ToggleInputTypeModule {
	export class Service {
		constructor() {
			document.addEventListener("readCompleteAction", () => {
				Service.toggleInputTypeChangeEvent()
			})
		}

		public static toggleInputTypeChangeEvent() {
			const $elements = document.querySelectorAll(".js__toggleInputType[data-target][data-type]")
			if ($elements) {
				$elements.forEach(($element) => {
					const $target = $element.getAttribute("data-target")
					if ($target) {
						const $targetElements = document.querySelectorAll($target)
						$targetElements.forEach(($targetElement) => {
							const $type = $targetElement.getAttribute("type")
							if ($type) {
								$targetElement.setAttribute("data-default", $type)
							}
						})

						$element.addEventListener("click", ($event) => {
							const $self = $element
							const $toggleClass = "js__toggleInputType--isActive"
							const $toggleType = $self.getAttribute("data-type")
							$self.classList.toggle($toggleClass)
							if ($toggleType) {
								if ($targetElements) {
									$targetElements.forEach(($targetElement) => {
										const $toggleDefault = $targetElement.getAttribute("data-default")
										if ($toggleDefault) {
											$targetElement.classList.toggle($toggleClass)
											if ($targetElement.classList.contains($toggleClass)) {
												$targetElement.setAttribute("type", $toggleType)
											} else {
												$targetElement.setAttribute("type", $toggleDefault)
											}
										}
									})
								}
							}
						})
					}
				})
			}
		}
	}
}
