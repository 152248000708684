/**
 * DatePicker
 */
import flatpickr from "flatpickr"
import { Japanese } from "flatpickr/dist/l10n/ja.js"

export namespace DatePickerModule {
	export class Service {
		//private static VanillajsDatepicker = require("vanillajs-datepicker");

		constructor() {
			document.addEventListener("readCompleteAction", () => {
				Service.setEvent()
			})
		}

		public static setEvent() {
			const $elements = document.querySelectorAll(".calendarWrap input")
			if ($elements.length) {
				const $nowDate = new Date()
				const $minDate = $nowDate.setDate($nowDate.getDate() + 1)
				if (flatpickr && flatpickr.l10ns && flatpickr.l10ns.ja) {
					flatpickr.l10ns.ja.firstDayOfWeek = 0
				}

				$elements.forEach(($element) => {
					$element.setAttribute("readonly", "readonly")

					if ("datetime-local" === $element.getAttribute("type")) {
						$element.setAttribute("type", "text")
						const $calendarWrap = $element.closest(".calendarWrap")
						if ($calendarWrap) {
							$calendarWrap.classList.add("calendarWrap--flatpickr-input-time")
						}

						$element.classList.add("flatpickr-input-time")

						flatpickr($element, {
							locale: Japanese,
							dateFormat: "Y/m/d H:i",
							time_24hr: true,
							enableTime: true,
							//'minDate': $minDate,
						})
					} else if ("calendar" === $element.getAttribute("type")) {
						flatpickr($element, {
							locale: Japanese,
							dateFormat: "Y/m/d",
						})
					} else if ("time-local" === $element.getAttribute("type")) {
						flatpickr($element, {
							locale: Japanese,
							enableTime: true,
							time_24hr: true,
							noCalendar: true,
							dateFormat: "H:i",
						})
					}
				})
				/**
         * TODO: 多分いらない
        Service.VanillajsDatepicker.Datepicker.locales.ja = {
          days: ["日曜", "月曜", "火曜", "水曜", "木曜", "金曜", "土曜"],
          daysShort: ["日", "月", "火", "水", "木", "金", "土"],
          daysMin: ["日", "月", "火", "水", "木", "金", "土"],
          months: ["1月", "2月", "3月", "4月", "5月", "6月", "7月", "8月", "9月", "10月", "11月", "12月"],
          monthsShort: ["1月", "2月", "3月", "4月", "5月", "6月", "7月", "8月", "9月", "10月", "11月", "12月"],
          today: "今日",
          format: "yyyy/mm/dd",
          titleFormat: "y年mm月",
          clear: "クリア"
        };

        $elements.forEach($element => {
          $element.addEventListener('focus', ($event) => {
            $element.setAttribute('readonly', 'readonly');
          });

          $element.addEventListener('focusout', ($event) => {
            $element.removeAttribute('readonly');
          });

          const $datepicker = new Service.VanillajsDatepicker.Datepicker($element, {
            // ...options
            language: 'ja',
            autohide: true,
          });
        });
         */
			}
		}
	}
}
