import Alpine from "alpinejs"
import * as Sentry from "@sentry/browser"
import "requestidlecallback-polyfill"

declare global {
	interface Window {
		Alpine: typeof Alpine
	}
}
window.Alpine = Alpine

const sentryInitialized = !!Sentry.getClient()
if (process.env.NODE_ENV === "production") {
	if (!sentryInitialized) {
		Sentry.init({
			dsn: "https://5a6a6b9fd317493cbbd272c5e6bf8e00@o1143709.ingest.sentry.io/6204639",
			release: import.meta.env.VITE_APP_VERSION,
		})
		console.log("Sentry enabled", {
			release: import.meta.env.VITE_APP_VERSION,
		})
	}
} else {
	if (!sentryInitialized) {
		console.log("Sentry disabled", {
			release: import.meta.env.VITE_APP_VERSION,
		})
	}
}

/**
 * デフォルトのモジュールを読込
 */
import "./Default"

/**
 * アコーディオン
 */
import { AccordionModule } from "./views/Components/Molecules/accordion/accordion"
const $accordionModule = new AccordionModule.Service()

/**
 * ツールチップ
 */
import { TooltipModule } from "./views/Components/Molecules/tooltip/tooltip"
const $tooltipModule = new TooltipModule.Service()

/**
 * トグルクラス
 */
import { ToggleClassModule } from "./views/Components/Molecules/toggleClass/toggleClass"
const $toggleClassModule = new ToggleClassModule.Service()

/**
 * トグルインプットタイプ
 */
import { ToggleInputTypeModule } from "./views/Components/Molecules/toggleInputType/toggleInputType"
const $toggleInputTypeModule = new ToggleInputTypeModule.Service()

/**
 * 入力チェック
 */
import { InputCheckModule } from "./views/Components/Molecules/inputCheck/inputCheck"
const $inputCheckModule = new InputCheckModule.Service()

/**
 * メッセージボックス
 */
import { MessageListBoxModule } from "./views/Components/Molecules/messageListBox/messageListBox"
const $messageListBoxModule = new MessageListBoxModule.Service()

/**
 * フッター
 */
import { DetailFooterModule } from "./views/Components/Organisms/detailFooter"
const $detailFooterModule = new DetailFooterModule.Service()

/**
 * アップロードファイルをアップロード前にプレビューする
 */
import { InputFilePreviewModule } from "./modules/inputFilePreview"
const $inputFilePreviewModule = new InputFilePreviewModule.Service()

/**
 * マルチアップロードファイル
 */
//import { InputFileMultipleModule } from './modules/inputFileMultiple';
//const $inputFileMultipleModule = new InputFileMultipleModule.Service();

/**
 * データボックスリンクを１度目は選択状態、２度目はリンクとする
 */
import { DataBoxListLinkModule } from "./views/Components/Molecules/dataBoxListLink/dataBoxListLink"
const $dataBoxListLinkModule = new DataBoxListLinkModule.Service()

/**
 * 「.tableBox--scroll」より「.tableBox__table」が大きいか判定
 */
import { TableBoxScrollModule } from "./views/Components/Molecules/tableBoxScroll/tableBoxScroll"
const $tableBoxScrollModule = new TableBoxScrollModule.Service()

/**
 * DataPicker
 */
import { DatePickerModule } from "./views/Components/Atoms/datePicker"
const $datePickerModule = new DatePickerModule.Service()

/**
 * Slider
 */
import { SliderModule } from "./views/Components/Molecules/slider/slider"
const $sliderModule = new SliderModule.Service()

/**
 * clickAction
 */
import { ClickActionModule } from "./views/Components/Atoms/js__clickAction"
const $clickActionModule = new ClickActionModule.Service()

/**
 * スクロールイベント
 */
import { ScrollEventModule } from "./modules/scroll_event"
const $scrollEventModule = new ScrollEventModule.Service()

/**
 * auto pager
 */
import { AutoPagerModule } from "./views/Components/Molecules/autoPager/autoPager"
const $autoPagerModule = new AutoPagerModule.Service()

/**
 * inputWrap--file
import { InputWrapFileLabelModule } from './views/Components/Molecules/inputWrap--file__label/inputWrap--file__label';
const $inputWrapFileLabelModule = new InputWrapFileLabelModule.Service();
 */

/**
 * クライアント側データBOXサイドバー
 */
import { ClientDataboxSidebarModule } from "./views/Components/Organisms/clientDataboxSidebar"
const $clientDataboxSidebarModule = new ClientDataboxSidebarModule.Service()

/**
 * 分割アップロード
 */
import { FlowjsFileUploadModule } from "./modules/flowjsFileUpload"
const $flowjsFileUploadModule = new FlowjsFileUploadModule.Service()

/**
 * アップロードファイル選択時にファイル名を表示
 */
import { InputWrapFileModule } from "./views/Components/Atoms/inputWrapFile"
const $inputWrapFileModule = new InputWrapFileModule.Service()

/**
 * 店舗展開日未定の場合、展開日のカレンダーにdisabledを付与する
 */
import { ToggleTargetDateModule } from "./views/Components/Molecules/toggleTargetDate/toggleTargetDate"
import { BrowserTracing } from "@sentry/tracing"
const $ToggleTargetDate = new ToggleTargetDateModule.Service()

Alpine.start()
