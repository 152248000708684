/**
 * アコーディオン
 * 「.js__accordion」に対して処理をする
 * 
<li class="js__accordion">
  <a class="js__accordionTrigger">botann</a>
  </a>
  <ul class="js__accordionTarget">
      ...
  </ul>
</li>
 */

import { functions } from "../../../../modules/functions"

export namespace AccordionModule {
	export class Service {
		constructor() {
			document.addEventListener("readCompleteAction", () => {
				Service.accordionSetEvent()
			})
		}

		public static accordionSetEvent() {
			const $elements = document.querySelectorAll(".js__accordion .js__accordionTrigger")
			if ($elements) {
				$elements.forEach(($element) => {
					if ($element.closest(".js__accordion")) {
						if (!$element.closest(".js__accordion")?.classList.contains("js__accordion--isActive")) {
							const $parent = $element.closest(".js__accordion")
							if ($parent) {
								const $target = $parent.querySelector<HTMLElement>(".js__accordionTarget")
								if ($target) {
									$target.style.display = "none"
								}
							}
						}
					}
					$element.addEventListener("click", ($event) => {
						const $eventTarget = $event.target as Element
						const $self = $element
						const $parent = $self.closest(".js__accordion")

						if ($eventTarget) {
							if (
								"a" === $eventTarget.nodeName &&
								"" !== $eventTarget.getAttribute("href") &&
								null !== $eventTarget.getAttribute("href")
							) {
								return
							}
							if (null !== $eventTarget.closest("a")) {
								const $aTag = $eventTarget.closest("a")
								if ($aTag) {
									if ("" !== $aTag.getAttribute("href") && null !== $aTag.getAttribute("href")) {
										console.log("tre")

										return
									}
								}
							}
						}

						if ($parent) {
							const $target = $parent.querySelector<HTMLElement>(".js__accordionTarget")
							if ($target) {
								$parent.classList.toggle("js__accordion--isActive")
								if ($parent.classList.contains("js__accordion--isActive")) {
									functions.slideDown($target)
								} else {
									functions.slideUp($target)
								}
							}
						}
					})
				})
			}
		}
	}
}
