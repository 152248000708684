import { Ease } from "./ease"

export namespace functions {
	/**
	 * ユニークID生成
	 */
	export function createUniqueID() {
		let $unique_id = "_" + Math.random().toString(36).substr(2, 9)
		while (document.getElementById($unique_id)) {
			$unique_id = "_" + Math.random().toString(36).substr(2, 9)
		}
		return $unique_id
	}

	/**
	 * addEventListenerで複数のイベント
	 *
	 * 使い方
	 * addEventListenerMultiType('mousedown touchstart', function handleEvent (event) { console.log(event); }, false);
	 */
	export function addEventListenerMultiType(
		element: any,
		types: string,
		listener: () => void,
		useCapture: boolean | AddEventListenerOptionsObject
	) {
		for (var $i = 0, $types = types.trim().split(/\s+/), $len = types.length; $i < $len; ++$i) {
			element.addEventListener($types[$i], listener, useCapture)
		}
	}

	interface AddEventListenerOptionsObject {
		capture?: boolean
		once?: boolean
		passive?: boolean
	}

	/**
	 * slideUp slideDown
	 */
	export interface slideOptions {
		duration?: number
		easing?: Function
		direction?: number
	}

	interface slideDirection {
		OPEN: number
		CLOSE: number
	}

	const defaults: slideOptions = {
		duration: 400,
		easing: (currentTime: number, startValue: number, diffValue: number, dureation: number) => {
			return -diffValue * (currentTime /= dureation) * (currentTime - 2) + startValue
		},
	}

	const directions: slideDirection = {
		OPEN: 1,
		CLOSE: 2,
	}

	export const slideUp = (element: HTMLElement, args: number | slideOptions = {}) => {
		if (isInteger(args)) {
			args = { duration: <number>args }
		}

		const options: any = extend(defaults, args)
		options.direction = directions.CLOSE
		options.to = 0
		options.startingHeight = element.scrollHeight
		options.distanceHeight = -options.startingHeight

		setElementAnimationStyles(element)

		window.requestAnimationFrame((timestamp: number) => animate(element, options, timestamp))
	}

	export const slideDown = (element: HTMLElement, args: number | slideOptions = {}) => {
		if (isInteger(args)) {
			args = { duration: <number>args }
		}

		element.style.height = "0px"
		setElementAnimationStyles(element)

		const options: any = extend(defaults, args)
		options.direction = directions.OPEN
		options.to = element.scrollHeight
		options.startingHeight = 0
		options.distanceHeight = options.to

		window.requestAnimationFrame((timestamp: number) => animate(element, options, timestamp))
	}

	const animate = (element: HTMLElement, options: any, now: number) => {
		if (!options.startTime) {
			options.startTime = now
		}

		const currentTime = now - options.startTime
		const animationContinue = currentTime < options.duration
		const newHeight: number = options.easing(
			currentTime,
			options.startingHeight,
			options.distanceHeight,
			options.duration
		)

		if (animationContinue) {
			element.style.height = `${newHeight.toFixed(2)}px`
			window.requestAnimationFrame((timestamp: number) => animate(element, options, timestamp))
		} else {
			if (options.direction === directions.CLOSE) {
				element.style.display = "none"
			}

			if (options.direction === directions.OPEN) {
				element.style.display = "block"
			}

			removeElementAnimationStyles(element)
		}
	}

	const setElementAnimationStyles = (element: HTMLElement) => {
		element.style.display = "block"
		element.style.overflow = "hidden"
		element.style.marginTop = "0"
		element.style.marginBottom = "0"
		element.style.paddingTop = "0"
		element.style.paddingBottom = "0"
	}

	const removeElementAnimationStyles = (element: HTMLElement) => {
		element.style.height = ""
		element.style.overflow = ""
		element.style.marginTop = ""
		element.style.marginBottom = ""
		element.style.paddingTop = ""
		element.style.paddingBottom = ""
	}

	const isInteger = (value: any) => {
		if (<any>Number.isInteger) {
			return Number.isInteger(value)
		} else {
			return typeof value === "number" && isFinite(value) && Math.floor(value) === value
		}
	}

	const extend = (defaults: any, options: any) => {
		const extendedOptions: any = {}
		for (const key in defaults) {
			extendedOptions[key] = options[key] || defaults[key]
		}
		return extendedOptions
	}

	/**
	 * ブラウザー情報取得
	 */
	export const getBrowser = () => {
		const $userAgent = window.navigator.userAgent.toLowerCase()
		let $return: string
		if ($userAgent.indexOf("msie") != -1 || $userAgent.indexOf("trident") != -1) {
			//IE向けの記述
			$return = "ie"
		} else if ($userAgent.indexOf("edge") != -1) {
			//旧Edge向けの記述
			$return = "edge"
		} else if ($userAgent.indexOf("chrome") != -1) {
			//Google Chrome向けの記述
			$return = "chrome"
		} else if ($userAgent.indexOf("safari") != -1) {
			//Safari向けの記述
			$return = "safari"
		} else if ($userAgent.indexOf("firefox") != -1) {
			//FireFox向けの記述
			$return = "firefox"
		} else if ($userAgent.indexOf("opera") != -1) {
			//Opera向けの記述
			$return = "opera"
		} else {
			//その他のブラウザ向けの記述
			$return = ""
		}

		return $return
	}

	/**
	 * OS情報取得
	 */
	export const getOS = () => {
		const $userAgent = window.navigator.userAgent.toLowerCase()
		let $return: string
		if ($userAgent.indexOf("windows nt") !== -1) {
			//windows向けの記述
			$return = "windows"
		} else if ($userAgent.indexOf("windows phone") !== -1) {
			//Windows Phone向けの記述
			$return = "windows-phone"
		} else if ($userAgent.indexOf("android") !== -1) {
			//android向けの記述
			$return = "android"
		} else if ($userAgent.indexOf("iphone") !== -1) {
			//iphone向けの記述
			$return = "ios"
		} else if ($userAgent.indexOf("ipad") > -1 || ($userAgent.indexOf("macintosh") > -1 && "ontouchend" in document)) {
			//ipad向けの記述
			$return = "ios"
		} else if ($userAgent.indexOf("mac os x") !== -1) {
			//mac向けの記述
			$return = "mac"
		} else if ($userAgent.indexOf("linux") !== -1) {
			//linux向けの記述
			$return = "linux"
		} else if ($userAgent.indexOf("freebsd") !== -1) {
			//FreeBSD向けの記述
			$return = "freebsd"
		} else if ($userAgent.indexOf("openbsd") !== -1) {
			//OpenBSD向けの記述
			$return = "openbsd"
		} else if ($userAgent.indexOf("netbsd") !== -1) {
			//NetBSD向けの記述
			$return = "netbsd"
		} else {
			//その他のOS向けの記述
			$return = ""
		}

		return $return
	}

	/**
	 * デバイス判定
	 * @returns string
	 */
	export const getDevice = () => {
		const $userAgent = window.navigator.userAgent.toLowerCase()
		let $return: string

		if ($userAgent.indexOf("iPhone") > 0 || ($userAgent.indexOf("Android") > 0 && $userAgent.indexOf("Mobile") > 0)) {
			$return = "sp"
		} else if (
			$userAgent.indexOf("iPad") > 0 ||
			$userAgent.indexOf("Android") > 0 ||
			($userAgent.indexOf("macintosh") > -1 && "ontouchend" in document)
		) {
			$return = "tablet"
		} else {
			$return = "pc"
		}

		return $return
	}

	/**
	 * タッチデバイス判定
	 */
	export const isTouchDevice = () => {
		return "ontouchstart" in window
	}

	/**
	 * ビューポートの高さを取得し、0.01を掛けて1%の値を算出して、vh単位の値を取得
	 */
	export const setVh = () => {
		const $vw = window.innerWidth * 0.01
		const $nowVw = document.documentElement.style.getPropertyValue("--vw")
		const $vh = window.innerHeight * 0.01
		const $nowVh = document.documentElement.style.getPropertyValue("--vh")
		if ($nowVw !== `${$vw}px` && $nowVh !== `${$vh}px`) {
			document.documentElement.style.setProperty("--vh", `${$vh}px`)
		}
		if ($nowVw !== `${$vw}px`) {
			document.documentElement.style.setProperty("--vw", `${$vw}px`)
		}

		const $mainHeaderHeight = document.getElementById("mainHeader")?.clientHeight
		if ($mainHeaderHeight) {
			document.documentElement.style.setProperty("--mainHeaderHeight", `${$mainHeaderHeight}px`)
		}
	}

	/**
	 * スムーズスクロール
	 */
	export const smoothScroll = ($event: Event | null, $targetElement: Element | HTMLElement, $duration = 500) => {
		const $currentPostion = document.documentElement.scrollTop || document.body.scrollTop

		if ($targetElement && $currentPostion) {
			if ($event) {
				$event.preventDefault()
				$event.stopPropagation()
			}
			const $targetPosition = window.pageYOffset + $targetElement.getBoundingClientRect().top - 115
			const $startTime = performance.now()
			const loop = ($nowTime: number) => {
				const $time = $nowTime - $startTime
				const $normalizedTime = $time / $duration
				if ($normalizedTime < 1) {
					window.scrollTo(
						0,
						$currentPostion + ($targetPosition - $currentPostion) * Ease.EaseFunction.easeInOut($normalizedTime)
					)
					requestAnimationFrame(loop)
				} else {
					window.scrollTo(0, $targetPosition)
				}
			}

			requestAnimationFrame(loop)
		}
	}

	export const trigger = ($element: HTMLElement | Element, $eventName: string) => {
		let $EVENT
		try {
			$EVENT = new Event($eventName)
		} catch (e) {
			$EVENT = document.createEvent("Event")
			$EVENT.initEvent($eventName, false, true)
		}
		$element.dispatchEvent($EVENT)
	}
}
