/**
 * フォーム送信時にボタンを無効化する
 */
export namespace FormDisable {
	export class Service {
		constructor() {
			document.addEventListener("readCompleteAction", this.inject)
		}

		inject = () => {
			const $forms = document.querySelectorAll("form.js__auto_disable")
			$forms.forEach(($form) => {
				$form.addEventListener("submit", (evt) => {
					const $buttons = $form.querySelectorAll('[type="submit"]')
					$buttons.forEach(($submit) => {
						// キャンセルボタン等にname属性で値を設定することでサーバサイドでキャンセル機能を実現している場合があるため、disabledを使用せずにreadonlyにする
						$submit.setAttribute("readonly", "readonly")
						$submit.classList.add("disabled")
						// setTimeout(() => {
						//   $submit.removeAttribute("disabled")
						// }, 5000)
					})
				})
			})
		}
	}
}
