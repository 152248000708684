/**
 * モーダル
 */
import { functions } from "./functions"

export namespace ModalModule {
	export class Service {
		static modalWrap: HTMLDivElement

		constructor() {
			document.addEventListener("readCompleteAction", () => {
				Service.modalSetEvent()
				Service.modalSetCloseEvent()
			})
		}

		public static modalSetEvent() {
			const $modalWraps = document.querySelectorAll("modalWrap")
			$modalWraps.forEach(($modalWrap) => {
				$modalWrap.parentNode?.removeChild($modalWrap)
			})

			Service.modalWrap = document.createElement("div")
			Service.modalWrap.setAttribute("id", "modalWrap")
			Service.modalWrap.classList.add("modalWrap")
			document.body.appendChild(Service.modalWrap)

			const $targetElements = document.querySelectorAll<HTMLElement>(".modal")
			$targetElements.forEach(($targetElement) => {
				// idをつける
				const $modalId = $targetElement.getAttribute("id")
				if ($modalId) {
					const $modalIdElements = document.querySelectorAll(`#${$modalId}`)
					if ($modalIdElements.length > 1) {
						const $modalNewId = functions.createUniqueID()
						$targetElement.setAttribute("id", $modalNewId)
					}
				} else {
					const $modalNewId = functions.createUniqueID()
					$targetElement.setAttribute("id", $modalNewId)
				}

				// モーダルを閉じる処理
				document.addEventListener("click", ($event) => {
					const $eventTarget = $event.target as Element
					if ($eventTarget.classList.contains("modal--isActive")) {
						$eventTarget.classList.remove("modal--isActive")
					}
					if ($eventTarget.classList.contains("modal__content")) {
						const $modal = $eventTarget.closest(".modal--isActive")
						if ($modal) {
							$modal.classList.remove("modal--isActive")
						}
					}
				})

				// モーダルを開くイベント
				let $EVENT
				try {
					$EVENT = new Event("modalOpen")
				} catch (e) {
					$EVENT = document.createEvent("Event")
					$EVENT.initEvent("modalOpen", false, true)
				}
				$targetElement.addEventListener(
					"modalOpen",
					() => {
						Service.modalSetOpenEvent($targetElement)
					},
					true
				)
			})

			// 動的DOM対策
			// aタグのクリック処理
			document.addEventListener("click", ($event) => {
				let $eventTarget = $event.target as HTMLElement
				let $nodeName = $eventTarget.nodeName.toLowerCase()
				let $targetId = $eventTarget.getAttribute("data-modal")

				if ("a" !== $nodeName && !$eventTarget.closest("a")) {
					return
				} else {
					if ("a" !== $nodeName) {
						const $tmp = $eventTarget.closest("a")
						if ($tmp) {
							$eventTarget = $tmp
							$nodeName = $eventTarget.nodeName.toLowerCase()
						} else {
							return
						}
					}
				}

				if ("a" === $nodeName && null === $eventTarget.getAttribute("href")) {
					return
				}

				if (!$targetId) {
					$targetId = $eventTarget.getAttribute("href")
					if ($targetId && "#" !== $targetId.slice(0, 1)) {
						return
					}
				}

				if ($targetId && $targetId !== "#") {
					const $modal = document.querySelector<HTMLElement>($targetId)
					if ($modal && $modal.classList.contains("modal")) {
						const $form = $eventTarget.closest("form")
						const $requireds = $form?.querySelectorAll<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>(
							"*[required]"
						)
						let $flg = true
						if ($form && $requireds) {
							$requireds.forEach(($required) => {
								if (!$required.value) {
									$flg = false
									setTimeout(() => {
										$form.reportValidity()
									}, 100)
								}
							})
						}
						if ($flg) {
							Service.modalSetOpenEvent($modal)
						}
					} else {
						return
					}
				}

				return false
			})

			// form enterkey 対策
			const $formButtons = document.querySelectorAll("form a.button--modal")
			$formButtons.forEach(($formButton) => {
				const $form = $formButton.closest("form")
				const $href = $formButton.getAttribute("href")
				if ($href) {
					const $modal = document.querySelector<HTMLElement>($href)
					if ($modal) {
						$form?.addEventListener("submit", ($event) => {
							$event.preventDefault()

							// モーダルを開くイベント
							Service.modalSetOpenEvent($modal)

							return false
						})
					}
				}
			})
		}

		public static modalSetOpenEvent($modal: HTMLElement) {
			const $modalCloneId = $modal.getAttribute("id") + "-clone"
			let $modalClone: HTMLElement | null

			if ($modalCloneId !== Service.modalWrap.querySelector(".modal")?.getAttribute("id")) {
				const $otherModal = Service.modalWrap.querySelector(".modal")
				if ($otherModal) {
					$otherModal?.parentNode?.removeChild($otherModal)
				}
				$modalClone = $modal.cloneNode(true) as HTMLElement
				$modalClone.setAttribute("id", $modalCloneId)
				Service.modalWrap.appendChild($modalClone)
			} else {
				$modalClone = Service.modalWrap.querySelector<HTMLElement>(".modal")
			}

			if ($modalClone) {
				const $dynamicTexts = $modalClone.querySelectorAll(".js__inputvalue[data-target]")
				$dynamicTexts.forEach(($dynamicText) => {
					const $target = $dynamicText.getAttribute("data-target")
					const $values = $dynamicText.getAttribute("data-value")
					if ($target) {
						const $targetElements = document.querySelectorAll<
							HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
						>($target)

						if ($targetElements.length > 1) {
							const $dataValues = new Array()
							$targetElements.forEach(($targetElement) => {
								if ($targetElement.nodeName.toLowerCase() === "input") {
									if (($targetElement as HTMLInputElement).checked) {
										$dataValues.push($targetElement.value)
									}
								}
							})
							if ($values && $dataValues.length) {
								const $valuesArray = JSON.parse($values)
								let $text = ""
								$dataValues.forEach(($dataValue) => {
									$text += $text ? "<br>" + $valuesArray[$dataValue] : $valuesArray[$dataValue]
								})
								$dynamicText.innerHTML = $text
							} else if ($dataValues.length) {
								let $text = ""
								$dataValues.forEach(($dataValue) => {
									$text += $text ? "<br>" + $dataValue : $dataValue
								})
								$dynamicText.innerHTML = $text
							} else {
								$dynamicText.innerHTML = ""
							}
						} else if ($targetElements.length === 1) {
							let $targetElement: HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement | null = $targetElements[0]
							if (
								$targetElement.nodeName.toLowerCase() === "input" &&
								("checkbox" === $targetElement.getAttribute("type") || "radio" === $targetElement.getAttribute("type"))
							) {
								if (!($targetElement as HTMLInputElement).checked) {
									$targetElement = null
								}
							}
							if ($targetElement) {
								let $text = ""
								if ($values) {
									const $valuesArray = JSON.parse($values)
									$text = $valuesArray[$targetElement.value] ?? $targetElement.value
								} else {
									$text = $targetElement.value
									$text = $text.replace(/\r\n/g, "<br>")
									$text = $text.replace(/(\n|\r)/g, "<br>")
								}
								$dynamicText.innerHTML = $text
							} else {
								$dynamicText.textContent = ""
							}
						}
					}
				})
				$modalClone.classList.add("modal--isActive")
			}
		}

		public static modalSetCloseEvent() {
			document.addEventListener("keydown", ($event) => {
				const $modal = document.querySelector(".modal.modal--isActive")
				if ($modal) {
					if ($event.keyCode === 27) {
						$modal.classList.remove("modal--isActive")
						return false
					}
				}
			})

			document.addEventListener("click", ($event) => {
				const $eventTarget = $event.target as Element
				const $modal = $eventTarget.closest(".modal.modal--isActive")
				if ($modal) {
					if ($eventTarget.classList.contains("modal__closeButton") || $eventTarget.closest(".modal__closeButton")) {
						$modal.classList.remove("modal--isActive")
						return false
					}

					if (
						$modal.classList.contains("modal--image") &&
						($eventTarget.classList.contains("modal__main") ||
							$eventTarget.classList.contains("modal__body") ||
							$eventTarget.classList.contains("modal__content") ||
							$eventTarget.classList.contains("modal--isActive"))
					) {
						$modal.classList.remove("modal--isActive")
						return false
					}
				}
			})
		}
	}
}
