/**
 * 「.inlutWrap--file input[type="file"]」
 */

export namespace InputWrapFileModule {
	export class Service {
		constructor() {
			document.addEventListener("readCompleteAction", () => {
				Service.setEvent()
			})
		}

		public static setEvent() {
			const $elements = document.querySelectorAll('.inputWrap--file input[type="file"]')
			$elements.forEach(($element) => {
				const $parent = $element.closest(".inputWrap--file")
				const $text = $parent?.querySelector(".inputWrap--file__filename")
				if ($text) {
					$element.addEventListener("change", ($event) => {
						const $eventTarget = $event.target as HTMLInputElement
						let $flg = true
						let $file: File

						if ($eventTarget) {
							if ($eventTarget.files === null) {
								$flg = false
							} else {
								$file = $eventTarget.files[0]
								if ($eventTarget.files && $eventTarget.files.length > 1) {
									alert("アップロードできる画像は1枚です。再度お試しください。")
									return
								}
								if (!$file || $file === null || $eventTarget.files.length > 1) {
									$flg = false
								}

								if ($flg) {
									$text.textContent = $file.name
								}
							}
						}
					})
				}
			})
		}
	}
}
