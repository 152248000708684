/**
 * lineClampから展開できるように
 */

export namespace LineClampModule {
	export class Service {
		constructor() {
			document.addEventListener("readCompleteAction", () => {
				Service.check()
				Service.setEvent()
			})
		}

		public static setEvent() {
			document.body.addEventListener("resize", ($event) => {
				Service.check()
			})

			Service.buttonAction()
		}

		private static check() {
			const $elements = document.querySelectorAll<HTMLElement>(".lineClamp")
			$elements.forEach(($element) => {
				const $offsetHeight = $element.offsetHeight
				const $scrollHeight = $element.scrollHeight
				if ($offsetHeight !== $scrollHeight) {
					if (!$element.classList.contains("lineClamp--isActive")) {
						$element.classList.add("lineClamp--isActive")
					}
				} else {
					if ($element.classList.contains("lineClamp--isActive")) {
						$element.classList.remove("lineClamp--isActive")
					}
				}
			})
		}

		private static buttonAction() {
			document.addEventListener("click", ($event) => {
				const $eventTarget = $event.target as HTMLElement
				if (
					$eventTarget &&
					($eventTarget.classList.contains("lineClampButton") || $eventTarget.closest(".lineClampButton"))
				) {
					const $button = $eventTarget.classList.contains("lineClampButton")
						? $eventTarget
						: $eventTarget.closest(".lineClampButton")
					const $buttonWrap = $button?.closest(".lineClampButtonWrap")
					if ($buttonWrap) {
						$event.preventDefault()
						const $showContent = $buttonWrap.previousElementSibling
						$showContent?.classList.toggle("lineClamp--isOpen")
						return false
					}
				}
			})
		}
	}
}
