/**
 * IE対策
 */
import "./modules/ie11"

/**
 * スマホ100vh対策
 */
import { ViewportModule } from "./modules/viewport"
const $viewport = new ViewportModule.Service()

/**
 * 読み込み完了
 */
import { LoadModule } from "./modules/load"
const $load = new LoadModule.Service()

/**
 * フォーム無効化
 */
import { FormDisable } from "./modules/formDisable"
const $form = new FormDisable.Service()

/**
 * 削除フォーム
 */
import { DeleteForm } from "./modules/deleteForm"
const $deleteForm = new DeleteForm.Service()

/**
 * マウスドラッグスクロール
 */
import { MouseDragScrollModule } from "./modules/mousedragscroll"
const $mouseDragScrollModule = new MouseDragScrollModule.Service()

/**
 * モーダル
 */
import { ModalModule } from "./modules/modal"
const $modalModule = new ModalModule.Service()

/**
 * 文字丸め
 */
import { LineClampModule } from "./views/Components/Atoms/lineClamp"
const $lineClampModule = new LineClampModule.Service()
