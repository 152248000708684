/**
 * 「.tableBox--scroll」より「.tableBox__table」が大きいか判定
 */

export namespace TableBoxScrollModule {
	export class Service {
		constructor() {
			document.addEventListener("readCompleteAction", () => {
				Service.setEvent()
			})
		}

		public static setEvent() {
			const $elements = document.querySelectorAll(".tableBox--scroll .tableBox__table")
			$elements.forEach(($element) => {
				Service.check($element)

				document.addEventListener("resize", () => {
					Service.check($element)
				})
			})
		}

		public static check($element: Element) {
			const $parent = $element.closest(".tableBox--scroll")
			if ($parent) {
				if ($parent.clientWidth < $element.clientWidth) {
					$parent.classList.add("tableBox--isScroll")
				} else {
					$parent.classList.remove("tableBox--isScroll")
				}
			}
		}
	}
}
